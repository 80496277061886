import { useColumnFilter } from 'hooks/useColumnFilter';
import React, { FC, useReducer, useCallback } from 'react';
import { t } from 'services/utils/translation';

import styles from '../index.module.scss';
import { Collapse, Table } from 'reactstrap';
import { ColumnFilter } from 'components/tables/ColumnFilter';
import { ColumnFilterItem } from '../../../components/tables/ColumnFilter';
import classNames from 'clsx';
import { ActionsDropdown } from 'components/tables/ActionsDropdown';
import { ActionsDropdownItem } from '../../../components/tables/ActionsDropdown';
import { ReactComponent as AddIcon } from 'img/icons/icon-pencil.svg';
import { ReactComponent as CreateIcon } from 'img/icons/icon-file.svg';
import { ReactComponent as ArchiveIcon } from 'img/icons/icon-layers.svg';
import { ReactComponent as DownLoadAllIcon } from 'img/icons/icon-downoload.svg';
import { ReactComponent as IconPackage } from 'img/icons/icon-package.svg';

import { ReactComponent as TrashIcon } from 'img/icons/icon-trash.svg';
import config from '../../../config.json';
import listTransition from '../../../styles/animaion/listTransition.module.scss';
import PopupDeleteForm from 'components/controls/PopupDeleteForm';
import SubheaderButton from 'components/layouts/SubheaderButton/SubheaderButton';
import ButtonLink from '../../../components/controls/ButtonLink/ButtonLink';
import ModalWrapper from 'components/controls/ModalWrapper/ModalWrapper';
import FilePreview from '../../../components/documents/FilePreview/FilePreview';
import FileBucketForm from '../../../components/documents/FileBucketForm/FileBucketForm';

import { parse } from 'date-fns';

import LoaderFetch from '../../../components/layouts/LoaderFetch/LoaderFetch';
import { IDocumentsPaginationParams } from 'services/api/documents/file-bucket/IFiltersDocuments';
import HeaderWithSearch from 'components/tables/HeaderWithSearch';
import HeaderWithDatePicker from 'components/tables/HeaderWithDatePicker';
import {
  getIcons,
  getTargetIcon,
} from 'pages/documents/utils/checkingForFileType/checkingForFileType';
import { CSSTransition } from 'react-transition-group';
import iconFadeAnimation from '../iconFade.module.scss';
import { IUseFetchV2, useGetListOld } from '../../../hooks/useGetListOld';
import { TABLE_COLOR } from 'services/constants/ThCellColor/ThCellColor';
import SubHeaderWrapper from 'components/tables/SubHeaderGreyWrapper/SubHeaderGreyWrapper';
import WrapperGrayTable from 'components/tables/WrapperGrayTable/WrapperGrayTable';
import { parentUIElement } from 'services/constants/parentUIElement';
import {
  formatDateForServerV2,
  isValidDate,
  sliceTimestamp,
} from '../../../services/utils/dateHelper/dateHelper';
import {
  deleteFileBucketApi,
  getFileBuckets,
} from 'services/api/documents/file-bucket/fileBucketApi';
import useModal, { IModal } from 'hooks/useModal';
import { extractGetLeftValueIfTrue } from 'services/utils/extractGetLeftValueIfTrue';
import useFilterBooleanSelect, {
  BooleanSelectField,
} from 'hooks/useFilterIsPruned/useFilterBooleanSelect';
import { THCustomSelect } from 'components/tables/THCustomWrapper/THCustomWrapper';
import HeaderWithSelectAdvanced from 'components/tables/HeaderWithSelectAdvanced/HeaderWithSelectAdvanced';
import useDoArchivedDocument from './hooks/useDocumentToArchive';
import { UI_TITLE } from 'services/localLocalization/UITitle';
import {
  COLUMN_LABELS_DOCUMENT,
  documentColumnVisibilityInitial,
  filtersDocument,
} from './documentColumnLabels';
import toDocumentsForList, {
  IFileBucketForList,
} from './utils/toDocumentForList';
import TDDocumnetArchive from './ui/TDDocumnetArchive/TDDocumnetArchive';
import useGetInitialDocumentPaginationParams from './hooks/useGetInitialDocumentPaginationParams';
import { getDocumentsUIText } from './consts';
import ToolTipWrapper from 'components/controls/ToolTipWrapper/ToolTipWrapper';
import useDownloadAllFiles from './hooks/useDownloadAllFiles';

const MODAL_DELETE_TITLE = t('Удалить документ');
const MODAL_EDIT_TITLE = t('Редактировать документ');
const MODAL_CREATE_TITLE = t('Создать документ');

const getModalTitle = extractGetLeftValueIfTrue(
  MODAL_EDIT_TITLE,
  MODAL_CREATE_TITLE
);
const {
  getHintForSentToArchive,
  TO_SENT_TO_ARCHIVE_TEXT,
  getHintForReceivedToArchive,
  TO_RECEIVED_TO_ARCHIVE_TEXT,
  getTitleTable,
} = getDocumentsUIText();

type TStateDocumentPage = {
  isPreviewModalOpen: boolean;
  fileBucket: IFileBucketForList | null;
  fileName: string | null;
};

const initialState: TStateDocumentPage = {
  isPreviewModalOpen: false,
  fileBucket: null,
  fileName: null,
};

type TActionDocumentPage =
  | {
      type: 'openPreviewModal';
      fileBucket: IFileBucketForList;
      fileName: string | null;
    }
  | { type: 'closePreviewModal' };

type PropsType = {
  parentUIName?: parentUIElement;
  parentUIPk?: string | number;
  refreshTabs?: () => void;
  viewOnly?: boolean;
  isDocumentRegistrarPage?: boolean;
};

const DocumentsPage: FC<PropsType> = (props) => {
  const {
    parentUIName,
    parentUIPk,
    refreshTabs,
    viewOnly = false,
    isDocumentRegistrarPage,
  } = props;

  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState);

  const initialParamsDocument = useGetInitialDocumentPaginationParams({
    isDocumentRegistrarPage,
    parentUIPk,
    parentUIName,
  });

  const {
    data: fileBuckets,
    isLoading,
    onSearchRequest,
    setStart,
    setLength,
    total,
    params: {
      skip,
      length,
      sent_to_archive: sentToArchiveFilter,
      received_to_archive: receivedToArchiveFilter,
    },
    params,
    setData,
    doRefresh,
  }: IUseFetchV2<
    IFileBucketForList[],
    IDocumentsPaginationParams
  > = useGetListOld({
    getDataApi: getFileBuckets,
    initialParams: initialParamsDocument,
    convertData: toDocumentsForList,
  });

  const refreshData = useCallback(() => {
    doRefresh();
    refreshTabs && refreshTabs();
  }, [doRefresh, refreshTabs]);

  const { visibility, onFilterItemToggle, applyVisibilityChanges } =
    useColumnFilter(
      documentColumnVisibilityInitial,
      `documentColumnFilter${parentUIName}`
    );

  const {
    state: { isAddEditFormOpen, isDeleteFormOpen, entity: targetDocument },
    openAddEditForm,
    closeAddEditForm,
    successHandler,
    toggleDeleteForm,
    entityDeleteHandler,
  }: IModal<IFileBucketForList> = useModal({
    setRefresh: refreshData,
    deleteRequest: deleteFileBucketApi,
    entityIdKey: 'pk',
  });

  //files list

  const toggleListDocumentsOpen = useCallback(
    (id: number) =>
      setData((prevEntities: IFileBucketForList[]) => {
        prevEntities = prevEntities.map((el: IFileBucketForList) =>
          el.pk === id ? { ...el, open: !el.open } : el
        );
        return prevEntities;
      }),
    [setData]
  );

  //Preview
  const toggleOpenPreview = async (
    fileBucket?: IFileBucketForList,
    fileName?: string
  ) => {
    if (fileBucket && fileName) {
      dispatch({ type: 'openPreviewModal', fileBucket, fileName });
    }
  };

  const toggleClosePreview = () => {
    dispatch({ type: 'closePreviewModal' });
  };

  //OTHER UTILS

  const onDownloadAllFiles = useDownloadAllFiles();

  const {
    handleSelectBooleanType: handleSelectSentToArchive,
    booleanOptions: booleanOptionsSentToArchive,
  } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.documentSentToArchive,
    old: true,
  });

  const {
    handleSelectBooleanType: handleSelectBooleanReceivedToArchive,
    booleanOptions: booleanOptionsReceivedToArchive,
  } = useFilterBooleanSelect({
    onSearchRequest,
    field: BooleanSelectField.documentReceivedToArchive,
    old: true,
  });

  const doArchivedDocument = useDoArchivedDocument(refreshData);

  return (
    <>
      <SubHeaderWrapper mainTitle={getTitleTable(!isDocumentRegistrarPage)}>
        {!viewOnly && !isDocumentRegistrarPage && (
          <SubheaderButton
            onClick={openAddEditForm.bind(this, undefined)}
            bordered={false}
          >
            <CreateIcon
              className={classNames(styles.svgIcon, 'mr-2')}
              style={{ lineHeight: 0.9 }}
            />
            <span className={classNames(styles.svgIcon)}>
              {t('Добавить документ')}
            </span>
          </SubheaderButton>
        )}
      </SubHeaderWrapper>
      <WrapperGrayTable
        pagerProps={{
          onPageChange: setStart,
          onLengthChange: setLength,
          total: total,
          length,
          start: skip,
        }}
      >
        <Table
          hover
          className={classNames(
            styles.myTable,
            'table table-bordered table-hover table-responsive-md table-responsive-none'
          )}
        >
          <tr className={styles.tableHeader}>
            <th
              className={classNames(styles.thPk, {
                'd-none': !visibility.pk,
              })}
            >
              <HeaderWithSearch
                title={COLUMN_LABELS_DOCUMENT.PK}
                onSearch={(searchString: string) =>
                  onSearchRequest(searchString, 'pk')
                }
                defaultValue={params?.pk?.toString()}
                headerColor={TABLE_COLOR.GREY}
                isOnlyNumbers
              />
            </th>
            <th
              className={classNames('p-2', styles.thName, {
                'd-none': !visibility.name,
              })}
            >
              <HeaderWithSearch
                title={COLUMN_LABELS_DOCUMENT.NAME}
                onSearch={(searchString: string) =>
                  onSearchRequest(searchString, 'file_bucket_name')
                }
                defaultValue={params?.file_bucket_name}
                headerColor={TABLE_COLOR.GREY}
              />
            </th>
            <th
              className={classNames(styles.thDateCreate, 'p-2', {
                'd-none': !visibility.date_create,
              })}
            >
              <HeaderWithDatePicker
                title={
                  params.time_added
                    ? params.time_added
                    : COLUMN_LABELS_DOCUMENT.DATE_CREATE
                }
                value={
                  params.time_added
                    ? parse(
                        params.time_added,
                        config.serverDateFormat,
                        new Date()
                      )
                    : null
                }
                onSearch={(date: Date) => {
                  if (!date || !isValidDate(date)) {
                    onSearchRequest('', 'time_added');

                    return;
                  }
                  const formattedDate = formatDateForServerV2(date);
                  onSearchRequest(formattedDate, 'time_added');
                }}
                headerColor={TABLE_COLOR.GREY}
              />
            </th>
            <THCustomSelect
              isVisible={visibility.sentToArchive}
              className={classNames(styles.thSentToArchive)}
            >
              <HeaderWithSelectAdvanced
                label={COLUMN_LABELS_DOCUMENT.SENT_TO_ARCHIVE}
                options={booleanOptionsSentToArchive}
                onChange={handleSelectSentToArchive}
                defaultValue={
                  sentToArchiveFilter === undefined
                    ? undefined
                    : {
                        value: !!sentToArchiveFilter,
                        label: getHintForSentToArchive(!!sentToArchiveFilter),
                      }
                }
                headerColor={TABLE_COLOR.GREY}
                disabled={isDocumentRegistrarPage}
              />
            </THCustomSelect>
            <THCustomSelect
              isVisible={visibility.receivedToArchive}
              className={classNames(styles.thReceivedToArchive)}
            >
              <HeaderWithSelectAdvanced
                label={COLUMN_LABELS_DOCUMENT.RECIVED_TO_ARCHIVE}
                options={booleanOptionsReceivedToArchive}
                onChange={handleSelectBooleanReceivedToArchive}
                defaultValue={
                  typeof receivedToArchiveFilter === 'boolean'
                    ? {
                        value: receivedToArchiveFilter,
                        label: getHintForReceivedToArchive(
                          receivedToArchiveFilter
                        ),
                      }
                    : undefined
                }
                disabled={isDocumentRegistrarPage}
              />
            </THCustomSelect>

            <th
              className={classNames(styles.thFiles, 'p-2', {
                'd-none': !visibility.documents,
              })}
            >
              {COLUMN_LABELS_DOCUMENT.DOCUMENTS}
            </th>
            <th className={classNames(styles['fixed-width'], 'actions p-0')}>
              <ColumnFilter onApply={applyVisibilityChanges}>
                {filtersDocument &&
                  filtersDocument.map(({ name, label }) => (
                    <ColumnFilterItem
                      key={name}
                      name={name}
                      label={label}
                      defaultChecked={visibility[name]}
                      onChange={onFilterItemToggle}
                    />
                  ))}
              </ColumnFilter>
            </th>
          </tr>

          {!isLoading && (
            <tbody>
              {fileBuckets &&
                fileBuckets.map((fileBucket: IFileBucketForList) => {
                  const {
                    pk,
                    file_bucket_name: name,
                    sent_to_archive,
                    sent_to_archive_at,
                    received_to_archive,
                    received_to_archive_at,
                    timeAdded,
                    open,
                    list_of_uploaded_file_names: fileNames,
                    sent_to_archive_author,
                    received_to_archive_author,
                    is_active,
                  } = fileBucket;
                  return (
                    <tr
                      id={`tooltip-${pk}`}
                      key={pk}
                      data-toggle="collapse"
                      data-target=".multi-collapse1"
                      aria-controls="multiCollapseExample1"
                      className={classNames({
                        trActive: !is_active,
                        trArchive: !is_active,
                      })}
                    >
                      {!is_active && (
                        <ToolTipWrapper
                          target={`tooltip-${pk}`}
                          placement="top"
                        >
                          {t('Документ удален')}
                        </ToolTipWrapper>
                      )}
                      <td className={classNames({ 'd-none': !visibility.pk })}>
                        {pk}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.name,
                        })}
                      >
                        {name}
                      </td>
                      <td
                        className={classNames({
                          'd-none': !visibility.date_create,
                        })}
                      >
                        {timeAdded}
                      </td>
                      <TDDocumnetArchive
                        isChecked={!!sent_to_archive}
                        isVisible={visibility.sentToArchive}
                        date={sent_to_archive_at}
                        id={`${pk}-sentToArchive`}
                        hint={getHintForSentToArchive(!!sent_to_archive)}
                        sentToArchive={!!sent_to_archive}
                        author={sent_to_archive_author}
                      />
                      <TDDocumnetArchive
                        isChecked={!!received_to_archive}
                        isVisible={visibility.receivedToArchive}
                        date={received_to_archive_at}
                        id={`${pk}-receivedToArchive`}
                        hint={getHintForReceivedToArchive(
                          !!received_to_archive
                        )}
                        receivedToArchive={!!received_to_archive}
                        author={received_to_archive_author}
                      />
                      <td
                        className={classNames({
                          'd-none': !visibility.documents,
                        })}
                      >
                        <div className={classNames(styles.tableIconsWrapper)}>
                          <CSSTransition
                            in={!open}
                            timeout={300}
                            classNames={iconFadeAnimation}
                            unmountOnExit
                            appear
                          >
                            <div style={{ display: 'flex' }}>
                              {getIcons(fileNames).map(
                                (icon: any, i: number) => (
                                  <div key={i} style={{ marginRight: '5px' }}>
                                    {icon}
                                  </div>
                                )
                              )}
                            </div>
                          </CSSTransition>

                          {fileNames?.length > 0 && (
                            <ButtonLink
                              text={`Смотреть все (${fileNames.length})`}
                              handleClick={toggleListDocumentsOpen.bind(
                                this,
                                pk
                              )}
                              classNameCustom={styles.buttonViewFiles}
                            />
                          )}
                        </div>
                        <Collapse isOpen={open}>
                          <CSSTransition
                            in={open}
                            timeout={600}
                            classNames={listTransition}
                            unmountOnExit
                            appear
                          >
                            <ul>
                              {fileNames?.map((fileName: string, i: number) => (
                                <li key={fileName + i}>
                                  <div className={styles.iconContainer}>
                                    {<div>{getTargetIcon(fileName)}</div>}
                                    <ButtonLink
                                      classNameCustom={styles.filename}
                                      disabled={!is_active}
                                      text={sliceTimestamp(fileName)}
                                      handleClick={async () => {
                                        await toggleOpenPreview(
                                          fileBucket,
                                          fileName
                                        );
                                      }}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </CSSTransition>
                        </Collapse>
                      </td>
                      {is_active && (
                        <td align="center">
                          {!viewOnly && (
                            <ActionsDropdown>
                              <ActionsDropdownItem
                                label={UI_TITLE.EDIT}
                                onClick={openAddEditForm.bind(this, fileBucket)}
                                icon={<AddIcon className="text-primary" />}
                              />

                              {!received_to_archive && !sent_to_archive && (
                                <ActionsDropdownItem
                                  label={TO_SENT_TO_ARCHIVE_TEXT}
                                  onClick={async () => {
                                    await doArchivedDocument({
                                      sent_to_archive: true,
                                      pk,
                                    });
                                  }}
                                  icon={
                                    <ArchiveIcon className="text-primary" />
                                  }
                                />
                              )}

                              {sent_to_archive && !received_to_archive && (
                                <ActionsDropdownItem
                                  label={TO_RECEIVED_TO_ARCHIVE_TEXT}
                                  onClick={async () => {
                                    await doArchivedDocument({
                                      received_to_archive: true,
                                      pk: fileBucket.pk,
                                    });
                                  }}
                                  icon={
                                    <IconPackage className="text-primary" />
                                  }
                                />
                              )}
                              <ActionsDropdownItem
                                label={UI_TITLE.DELETE}
                                onClick={() => {
                                  toggleDeleteForm(fileBucket);
                                }}
                                icon={<TrashIcon className="text-primary" />}
                              />
                              <ActionsDropdownItem
                                label={t('Скачать все')}
                                onClick={onDownloadAllFiles.bind(
                                  null,
                                  fileNames
                                )}
                                icon={
                                  <DownLoadAllIcon
                                    className="text-primary  "
                                    style={{ width: '18px', height: '18px' }}
                                  />
                                }
                              />
                            </ActionsDropdown>
                          )}
                        </td>
                      )}
                      {!is_active && <div />}
                    </tr>
                  );
                })}
              {fileBuckets.length === 0 && (
                <tr>
                  <td colSpan={5}>{t('Документы отсутствуют')}</td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </WrapperGrayTable>

      {isLoading && <LoaderFetch />}
      {targetDocument?.pk && (
        <PopupDeleteForm
          headerLabel={MODAL_DELETE_TITLE}
          entityId={targetDocument?.pk}
          entityName={targetDocument?.file_bucket_name}
          deleteHandler={entityDeleteHandler}
          isOpen={isDeleteFormOpen}
          toggleHandler={toggleDeleteForm}
        />
      )}

      <ModalWrapper
        headerLabel={getModalTitle(!!targetDocument?.pk)}
        isOpen={isAddEditFormOpen}
        closeHandler={closeAddEditForm}
      >
        {parentUIName && (
          <FileBucketForm
            parent_ui_element={{ [parentUIName]: parentUIPk }}
            toggleCloseCreateModal={successHandler}
            pk={targetDocument?.pk}
          />
        )}
      </ModalWrapper>

      {state.fileBucket && state.fileName && (
        <ModalWrapper
          headerLabel={state.fileName && sliceTimestamp(state.fileName)}
          isOpen={state.isPreviewModalOpen}
          closeHandler={toggleClosePreview}
          size="lg"
        >
          <FilePreview
            fileBucket={state.fileBucket}
            fileName={state.fileName}
          />
        </ModalWrapper>
      )}
    </>
  );
};

const reducer = (state: TStateDocumentPage, action: TActionDocumentPage) => {
  switch (action.type) {
    case 'openPreviewModal':
      return {
        ...state,
        isPreviewModalOpen: true,
        fileBucket: action.fileBucket,
        fileName: action.fileName,
      };
    case 'closePreviewModal':
      return {
        ...state,
        isPreviewModalOpen: false,
        fileBucket: null,
      };
    default:
      throw new Error('Action is undefined.');
  }
};

export default DocumentsPage;
